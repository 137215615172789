.success-ring-color {
  border-color: #a5dc86;
}

.checkmark-line {
  height: 5px;
  background-color: #a5dc86;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 2;
}

.line-short {
  width: 25px;
  left: 14px;
  top: 46px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-animation: animateSuccessTip 0.75s;
  animation: animateSuccessTip 0.75s;
}

.line-right {
  width: 47px;
  right: 8px;
  top: 38px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: animateSuccessLong 0.75s;
  animation: animateSuccessLong 0.75s;
}

.success-icon {
  width: 80px;
  height: 80px;
  border-width: 4px;
  border-style: solid;
  border-radius: 50%;
  padding: 0;
  position: relative;
  box-sizing: content-box;
  margin: 20px auto;
}

.success-icon:first-child {
  margin-top: 32px;
}

@keyframes animateSuccessLong {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0;
    top: 35px;
  }
  to {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

@keyframes animateSuccessTip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  to {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

/* Styles for Confirmation Page */
.payment-success-page {
  padding: 20px;
}

.payment-content {
  max-width: 500px;
  margin: auto;
}

.confirmation-message {
  text-align: center;
  margin-top: 50px;
}

@media only screen and (max-width: 400px) {
  .confirmation-message {
    margin-top: 30px;
  }
  .payment-success-page h1 {
    padding-top: 1em;
  }
}

.payment-success-hr {
  width: 70%;
  margin: auto;
  border-top: 1px solid rgba(128, 128, 128, 0.189);
}

.confirmation-number {
  text-align: center;
  font-size: 0.9rem;
  color: rgb(52, 52, 52);
}
