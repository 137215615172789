/* General Styles */
body {
  margin: 0px;
}
p {
  white-space: pre-wrap;
}

select {
  cursor: pointer;
}

.dashboard h1,
.dashboard h2 {
  margin-block-start: 0;
}

.bold {
  font-weight: bold;
}

.bg-fff {
  background-color: #fff;
}

.color-primary {
  color: #123b62;
  text-decoration: none;
}

.text-small {
  font-size: 12px;
}

.color-muted {
  color: rgba(19, 19, 19, 0.722);
}

.mb-30 {
  margin-bottom: 30px;
}

.hide {
  display: none;
}

/* Style top navigation bar */
.header {
  background-color: #1c4d7c;
  height: 9rem;
}
.header a {
  float: right;
  color: white;
  text-align: right;
  font-size: 17px;
  font-weight: 800px !important;
  font-family: "Open Sans";
  font-display: swap;
  padding: 11px;
  margin: 45px 25px 25px 25px;
}
.logo {
  float: left;
  position: absolute;
  top: -0.6000000000000014em;
  left: 0px;
  width: 170px;
}
ul {
  list-style-type: none;
  margin: 0px;
}
.Login {
  background-color: #c1902d;
  border-radius: 0.5em;
}
.profile_picture {
  height: 5rem;
  width: 5rem;
  float: right;
  padding: 2em;
  cursor: pointer;
  border-radius: 50%;
  object-fit: cover;
}

/* Welcome Section Styling */
.welcome-container {
  position: relative;
}
.welcome-container:before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.5;
  width: 100%;
  height: 14em;
  background-image: url("https://i.postimg.cc/66SfWDJt/linkedin-sales-solutions-Vt-Ko-Sy-Xz-NU-unsplash.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  filter: drop-shadow(0px 3px 2px black);
  margin-top: -1em;
  padding: 1em 0;
}
.welcome-content {
  position: relative;
  text-align: center;
}
h1 {
  font-size: 20px;
  color: #1c4d7c;
  text-align: center;
  padding-top: 2em;
  font-family: "Open Sans";
  font-display: swap;
  font-weight: 800px !important;
}
.signup-button {
  color: white;
  cursor: pointer;
  background-color: #1c4d7c;
  font-family: "Open Sans";
  font-display: swap;
  border-radius: 0.5em;
  border: none;
  font-size: 14px;
  text-align: center;
  margin: auto;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  box-shadow: -1px 3px 6px 0 rgb(52 52 52 / 35%);
}

.signup-button-container {
  text-align: center;
  margin: 0 auto;
  display: flex;
}

/* Classes Section Styling */
h2 {
  font-size: 0.8em;
  color: #1c4d7c;
  text-align: center;
  padding-top: 2em;
  font-family: "Open Sans";
  font-display: swap;
  font-weight: 800px !important;
}
.classes-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}
.classes-container:before {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url("./assets/Absolutely-Powerful-Classes-8.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  opacity: 0.17;
  scale: 1.6;
  z-index: -1;
}
.classes--card {
  position: relative;
  border: 1px solid black;
  margin: auto;
  margin: 2em 2%;
  width: 20%;
  border-bottom: 1em solid #1c4d7c;
  border-radius: 0.5em;
  box-shadow: 2px 2px 2px gray;
  background-color: white;
}
.classes--card-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 7em;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.classes--card-text {
  padding-left: 1em;
  padding-right: 1em;
  margin-top: -2em;
}
.classes--card-name {
  text-align: left;
}
.classes--card-details {
  text-align: left;
  font-size: 12px;
  color: black;
  font-family: "Open Sans";
  font-display: swap;
  height: 5.5rem;
  text-overflow: ellipsis;
  overflow: auto;
  overflow-x: hidden;
}
.h-50 {
  height: 50%;
}
.h-154 {
  height: 174px;
}
.hr {
  background-color: #1c4d7c;
  height: 4em;
}
.classes--card-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 50px;
}

@media only screen and (max-width: 1200px) {
  /* Fixing Overflow Text[1200px] */
  .classes--card {
    width: 40%;
    margin: auto;
    margin-top: 40px;
  }
  img {
    object-position: center;
  }
}

@media only screen and (max-width: 786px) {
  /* Small smartphones and Tablets [786px] */
  .classes--card {
    width: 40%;
    margin: auto;
    margin-top: 40px;
  }
  img {
    object-position: center;
  }
}

@media only screen and (max-width: 550px) {
  /* Small smartphones and Tablets [786px] */
  .classes--card {
    width: 80%;
    margin: auto;
    margin-top: 40px;
  }
}

/* Footer Styling */
.footer {
  background-color: #123b62;
}
.footer-left {
  font-family: "Open Sans";
  font-display: swap;
  color: #c1902d;
  float: left;
  margin-left: 9em;
  margin-top: 2em;
  text-align: left;
}
.footer-right {
  font-family: "Open Sans";
  font-display: swap;
  color: #c1902d;
  float: right;
  margin-right: 11em;
  margin-top: 4em;
  text-align: left;
}
.footer-privacy-policy {
  font-family: "Open Sans";
  font-display: swap;
  color: #c1902d;
  float: right;
  margin-right: 11em;
  text-align: left;
}
.footer-image {
  width: 17em;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.brain-jar-link {
  text-align: center;
  color: white;
  font-family: "Open Sans";
  font-display: swap;
  font-size: 0.5em;
}
.a .brain-jar-link {
  color: white;
  text-align: center;
}
@media only screen and (max-width: 1030px) {
  /* Small smartphones and Tablets [786px] */
  .footer {
    display: flex;
    flex-direction: column;
  }
  .footer-right {
    margin: auto;
    text-align: center;
  }
  .footer-left {
    margin: auto;
    text-align: center;
  }
}

/* Hamburger Styling */
.hamburger {
  display: none;
  float: right;
  cursor: pointer;
}
.menuIcon {
  display: none;
  float: right;
}
.closeIcon {
  display: none;
  float: right;
}
@media (max-width: 700px) {
  /* * {
    margin: 0;
    padding: 0;
    box-sizing: 0;
  } */
  .menuItem {
    display: flex;
    margin: auto;
    text-align: center;
    font-size: 10rem;
    color: white;
  }
  .hamburger {
    display: block;
    position: absolute;
    z-index: 101;
    top: 1rem;
    right: 1rem;
    padding: 4px;
    border: #123b62 solid 1px;
    background-color: #123b62;
    opacity: 0.9;
  }
  .menu {
    position: fixed;
    transform: translateY(-100%);
    transition: transform 0.2s;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background: #1c4d7c;
    color: white;
    list-style: none;
    padding-top: 4rem;
    display: flex;
    flex-direction: column;
  }
  .showMenu {
    transform: translateY(0);
  }
  .closeIcon {
    display: none;
    color: white;
    background-color: #1c4d7c;
    opacity: 0.9;
  }
  .menuIcon {
    display: block;
    background-color: #1c4d7c;
    opacity: 0.9;
    color: white;
  }
}

/*  Styles to Login Modal  */
.modal {
  /* background-color: rgba(255, 255, 255, 0.351); */
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9999;
}

.login--form-container {
  background-color: white;
  border: 1px solid black;
  margin: auto;
  margin-top: 20px;
  padding: 15px;
  width: 30%;
  font-family: "Open Sans";
  font-display: swap;
}

.signup--form--button {
  background-color: #1c4d7c;
  color: white;
  font-family: "Open Sans";
  font-display: swap;
  border: none;
  padding: 0.5em;
  font-weight: bold;
  cursor: pointer;
}
.form--underline {
  text-decoration: underline;
  text-decoration-color: #1c4d7c;
}

@media only screen and (max-width: 980px) {
  .login--form-container {
    width: 60%;
  }
}

@media only screen and (max-width: 768px) {
  .login--form-container {
    width: 80%;
  }
}

.login--form-header h2 {
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding-top: 0;
}

.login--form-header button {
  float: right;
  background-color: #1c4d7c;
  color: white;
  border: none;
  cursor: pointer;
}

.login--form-header ul {
  margin: 24px 0px;
  padding-left: 2.5%;
  width: 100%;
  display: flex;
}

.login--form-header ul li {
  list-style: none;
  margin-right: 30px;
  cursor: pointer;
}

.login--form {
  margin-bottom: 50px;
  font-family: "Open Sans";
  font-display: swap;
}

.login--form label,
form.login--form input[type="radio"] {
  margin-left: 2.5%;
}

form.login--form input[type="text"],
.form--textarea,
form.login--form input[type="email"],
form.login--form input[type="tel"],
form.login--form input[type="password"] {
  border-radius: 10px;
  border: 01px solid black;
  margin: 5px 0 13px 2.5%;
  padding: 10px 0px;
  padding-left: 10px;
  width: 90%;
}

.form--textarea {
  height: 200px;
}

form.login--form input[type="submit"] {
  margin-left: 25%;
  margin-top: 20px;
  width: 50%;
}

/* Styles to Create Lesson Page */
.create-lesson--select,
.create-lesson--input,
.lesson--form-savebtn,
.lesson--form-cancelbtn,
.mobile-logout {
  box-shadow: -1px 3px 6px 0 rgb(52 52 52 / 35%);
}

.lesson-form-btn_container {
  display: flex;
  justify-content: end;
  margin-right: 130px;
}

.lesson--form-savebtn {
  background-color: #1c4d7c;
  font-size: 17px;
  font-family: "Open Sans";
  font-display: swap;
  color: white;
  cursor: pointer;
  border: none;
  width: 100px !important;
  margin-left: 20px !important;
  padding: 10px;
}

.lesson--form-cancelbtn {
  width: 100px;
  font-family: "Open Sans";
  font-display: swap;
  color: #1c4d7c;
  padding: 10px;
  cursor: pointer;
  border: 1px solid #1c4d7c;
  margin-top: 20px;
  font-size: 17px;
}

/*  Styles to Dashboard Navbar  */
.dashboard-heading {
  background: #fff;
  padding-top: 0.83em;
  padding-bottom: 0.83em;
}

.dashboard--h1 {
  text-align: left;
  padding-top: 0px;
  padding-left: 10px;
  margin-block-start: 0em;
  margin-block-end: 0em;
}
.dashboard--navbar {
  background-color: #c1902d;
  height: 2rem;
}
.dashboard--menu {
  list-style-type: none;
  display: flex;
  justify-content: space-evenly;
  padding-inline-start: 0px;
}

@media (max-width: 700px) {
  .desktop-navigation {
    display: none;
  }
}

/*Nav Bar Mobile Responsiveness*/
@media (max-width: 550px) {
  .dashboard--menu {
    display: flex;
    flex-wrap: wrap;
  }
  .dashboard--navbar {
    height: 3rem;
  }
}

.dashboard--menu li {
  display: inline;
  padding-right: 10px;
}

.dashboard--menuItem {
  color: #1c4d7c;
  margin-top: 20px;
  margin: 0 auto;
  font-size: 19px;
  font-family: "Open Sans";
  font-display: swap;
  text-decoration: none;
}

.mobile-navigation {
  display: none;
}

/* Styles to Mobile Navbar */
@media (max-width: 700px) {
  .mobile-nav-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
  .mobile-navigation {
    display: block;
  }
  .menu {
    padding-inline-start: 0px;
  }
  .profile_picture {
    float: none;
    padding: 1em;
  }
  .mobile_dashboard--menu-li {
    padding-right: 0px;
    text-align: center;
    margin: 10px;
    width: 100%;
  }
  .mobile_dashboard--menu-li a {
    float: none;
    background-color: #c1902d;
    border: 1px solid #0f3459;
    color: #0f3459;
    padding: 10px;
    width: 80%;
    display: block;
    text-align: center;
    margin: 0px auto !important;
  }
  .dashboard-heading {
    background-color: transparent;
    padding-top: 0px;
  }
  .dashboard--h1 {
    color: #fff;
    text-align: center;
    padding-left: 0px;
  }
  .dashboard--navbar {
    background-color: transparent;
    height: auto;
  }
  .dashboard--menu {
    flex-wrap: wrap;
  }
  .mobile-logout {
    background-color: #0f3459;
    border: none;
    border-radius: 0.5em;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    margin: auto;
    padding: 0.7em 1.2em;
    margin-bottom: 1em;
    font-weight: 600;
  }
  .profile_picture-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

/*  Styles to Dashboard Page  */
.dashboard {
  position: relative;
  overflow: hidden;
}

.dashboard:before {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url("./assets/Absolutely-Powerful-Classes-8.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  opacity: 0.17;
  scale: 1.6;
  z-index: -1;
}

@media (min-width: 900px) {
  .dashboard:before {
    min-height: 75vh;
  }
}

@media (min-width: 1500px) {
  .dashboard:before {
    height: 80%;
  }
}

.dashboard_content {
  overflow: hidden;
  margin: auto !important;
  max-width: 1090px;
  min-height: 35rem;
  position: relative;
  z-index: 2;
}

/* Styles to Catalog Page */
.catalog--h2 {
  font-size: 20px;
  color: black;
  font-family: "Open Sans";
  font-display: swap;
  padding-top: 2em;
  padding-left: 2em;
  text-align: left;
}

.class-card {
  position: relative;
  border: 1px solid black;
  margin-top: 2em;
  margin-bottom: 2em;
  width: 30%;
  max-width: 300px;
  border-radius: 0.5em;
  box-shadow: 2px 2px 2px gray;
  background-color: white;
  font-family: "Open Sans";
  font-display: swap;
  text-align: center;
}

.class-card-heading {
  display: block;
  font-size: 1.17em;
  margin-block-start: 0em;
  padding-top: 1em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  color: black;
}

.class-card--btn {
  background-color: #1c4d7c;
  border: none;
  border-radius: 0.5em;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  margin: auto;
  padding: 1em;
  margin-bottom: 1em;
  font-weight: 600;
}

.class-card--hr {
  width: 80%;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: auto;
  margin-inline-end: auto;
}

.class-card-description {
  height: 118px;
  overflow: auto;
}

.class-card--container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 0px 2em;
}

.class-card--container a {
  color: #123b62;
  text-decoration: none;
}

.class-card--container a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .class-card {
    width: 45%;
  }
  .catalog--h2 {
    text-align: center;
    padding-left: 0;
  }
}

@media (max-width: 560px) {
  .class-card {
    width: 90%;
  }
  .class-card--container {
    justify-content: center;
  }
}

/*  Styles to Lessons Page  */
.lessons--heading {
  margin-left: 3em;
  margin-top: 30px;
}
.lessons--h1 {
  text-align: left;
  padding-top: 0px;
}
.createbtn {
  background-color: #1c4d7c;
  font-size: 17px;
  font-family: "Open Sans";
  font-display: swap;
  color: white;
  cursor: pointer;
  border: none;
  border-radius: 3px;
  padding: 5px;
  box-shadow: -1px 3px 6px 0 rgb(52, 52, 52);
  padding-left: 20px;
  padding-right: 20px;
}
table {
  box-shadow: -1px 3px 6px 0 rgb(52 52 52 / 35%);
  margin: 3em;
}
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}
th,
td {
  width: 49em;
  height: 40px;
  font-size: 15px;
  font-family: "Open Sans";
  font-display: swap;
  text-align: center;
}

td {
  background-color: #fff;
}

th {
  background-color: #123b62;
  color: white;
}

.login--underline {
  color: red;
}

.create-lesson--select {
  margin: 10px 0;
  margin-left: 2.5%;

  font-size: 15px;
  height: 20px;
}

/* Styles to About page */
.about_h1 {
  font-size: 30px;
}
.about_content {
  display: flex;
  justify-content: space-between;
  padding-top: 5em;
  padding-bottom: 5em;
}
.about_image {
  width: 50%;
  padding-left: 2em;
}
.about_paragraph {
  font-family: "Open Sans";
  font-display: swap;
  display: block;
  padding-left: 4em;
  width: 50%;
}
@media (max-width: 1200px) {
  .about_content {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
  }
  .about_image {
    width: 80%;
    height: 80%;
    margin: auto;
  }
  .about_paragraph {
    width: 80%;
    height: 80%;
  }
}

/* Styles to Classes Page */
.progress_bar {
  height: 20px;
  width: 80%;
  margin: auto;
  border: 1px solid black;
  border-radius: 10px;
}

.progress_status {
  height: 20px;
  background-color: #123b62;
  border-radius: 10px;
  box-shadow: -1px 3px 6px 0 rgb(52, 52, 52);
}

/* Styles to Uploads form */
.uploads-form {
  min-height: 500px;
  background-color: rgba(255, 255, 255, 0.285);
  padding-top: 10px;
}

.test-input {
  max-width: 100% !important;
  min-width: 0 !important;
  height: 500px !important;
  justify-content: center !important;
}

.test-input > div {
  flex-grow: 0 !important;
  flex-direction: column !important;
  align-items: center !important;
}

.upload-button {
  position: absolute;
}

.uploads-form-submit_btn {
  background-color: cornflowerblue;
}

/* Styles to Available Classes Page */
.intake_button {
  color: white;
  cursor: pointer;
  background-color: #1c4d7c;
  font-family: "Open Sans";
  font-display: swap;
  border-radius: 0.5em;
  border: none;
  font-size: 14px;
  text-align: center;
  margin: auto;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  font-weight: 600;
  box-shadow: -1px 3px 6px 0 rgb(52 52 52 / 35%);
}

.continue_button {
  box-shadow: -1px 3px 6px 0 rgb(52, 52, 52);
  color: white;
  cursor: pointer;
  background-color: #1c4d7c;
  font-family: "Open Sans";
  font-display: swap;
  border-radius: 0.5em;
  border: none;
  font-size: 14px;
  text-align: center;
  margin: auto;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  font-weight: 600;
}

/* Styles to Class Registration Page */
.class_registration {
  font-family: "Open Sans";
  font-display: swap;
  padding-left: 1em;
}

.registration_title {
  display: inline-block;
  color: #123b62;
  font-weight: bold;
}

.registration_sm_txt,
.privacy-policy_sm_txt {
  font-size: 9px;
}

.registration_form_btn {
  border-radius: 0.5em;

  font-size: 12px;
  text-align: center;
  margin: auto;
}

/* Styles to Lessons Page */
.lessons-page {
  padding: 20px;
}

.coming-soon-btn {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  width: 100%;
}

#assignment {
  width: 100%;
  height: 20em;
}

.lesson_btn {
  margin-bottom: 2em;
}

.disabled {
  cursor: auto;
  background-color: #123b62bb;
}

@media (max-width: 1150px) {
  /* Prevent overflow on smaller screen sizes */
  #assignment {
    width: 97%;
  }
}

/* Styles to Assessment Success Page */
.assessment_content {
  text-align: center;
  font-family: "Open Sans";
  font-display: swap;
}

.assessment--btn_container {
  display: flex;
  justify-content: space-between;
  padding: 0px 40px;
  margin-bottom: 30px;
}

.assessment_button {
  background-color: #1c4d7c;
  color: white;
  font-family: "Open Sans";
  font-display: swap;
  border: none;
  padding: 0.5em;
  font-weight: bold;
  cursor: pointer;
  margin-top: 25em;
  display: inline-block;
}

/* Styles to Profile Page */
.one_line {
  display: inline-block;
  padding-right: 1em;
  margin-left: 1em;
}

.row_one_label {
  width: 20em;
  height: 2em;
  margin-bottom: 1em;
}

.profile-form-photo {
  height: 5rem;
  width: 5rem;
  display: block;
  margin: 0 auto;
  margin-top: 2rem;
  border-radius: 50%;
  object-fit: cover;
}

.flexCenter {
  display: flex;
  justify-content: center;
}

.profile-form-photo-input {
  border: 1px solid #f7f2f275;
  margin: 1em 0 2em 0;
}

.profile-form {
  font-family: "Open Sans";
  font-display: swap;
  text-align: left;
}

.logout {
  color: white;
  cursor: pointer;
  background-color: maroon;
  font-family: "Open Sans";
  font-display: swap;
  border: none;
  font-size: 12px;
  text-align: center;
  margin: auto;
  font-weight: bold;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 1em;
  padding-right: 1em;
  margin-bottom: 2em;
}

.logout_center {
  text-align: center;
  margin: 2em 0;
}

/* Temporary Styles */
.placeholder {
  min-height: 80vh;
}

.placeholder-space {
  height: 16px;
}

.pb {
  padding-bottom: 15px;
}

.back-bnt {
  background-color: lightslategrey;
  color: white;
  font-family: "Open Sans";
  font-display: swap;
  border: none;
  padding: 0.5em;
  font-weight: bold;
  height: 30px;
  cursor: pointer;
}
.error-message {
  position: absolute;
  padding: 0;
  margin-top: 7px;
  margin-left: 20px;
  color: red;
  font-size: 14px;
}

.submitBtnContainer {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.submitBtnContainer input {
  padding: 1em 0.7em;
}

.submitBtnContainer input,
.logout {
  box-shadow: -1px 3px 6px 0 rgb(52 52 52 / 35%);
  border-radius: 3px;
}

.hidden {
  display: none;
}

/* Styles to Instructor Dashboard Page */
.instructor--dashboard-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 40px;
}

.instructor--dashboard-h1 {
  margin: 0px 20px;
  padding: 0px 20px;
}

.instructor--dashboard-searchbar {
  width: 65%;
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .instructor--dashboard-searchbar {
    padding: 20px 0;
    width: 100%;
  }
}

.grid-2X2 {
  display: grid;
  grid-template-columns: 1fr 400px;
}

@media (max-width: 930px) {
  .grid-2X2 {
    display: block;
  }
}

.assignment-table--btn {
  background-color: transparent;
  border: none;
  height: 100%;
  width: 100%;
  color: blue;
  text-decoration: underline;
  font-size: 15.5px;
  cursor: pointer;
}

/* Styles to Instructor Class Enrollment Modal */
.modal--fixed {
  background-color: rgba(255, 255, 255, 0.351);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
}

.close-btn {
  float: right;
  cursor: pointer;
  background-color: #123b62;
  color: white;
  border: none;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 1em;
  padding-right: 1em;
}

.class-enrollment--container {
  overflow-x: hidden;
  background-color: white;
  border: 1px solid black;
  margin: 50px;
  height: 80%;
  overflow: auto;
  padding: 20px;
}

/* Styles to Student Info page */
@media (min-width: 768px) {
  .grid-1X2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.grid-multiX2 {
  display: grid;
  grid-template-columns: 150px 1fr;
  margin-top: 50px;
}

.grid-multiX2 p {
  min-height: 40px;
}

/* Styles to Attendance Page */
.attendance-table {
  margin: 0;
}

.attendance-form {
  width: 80%;
  margin: auto;
}

.notes-container {
  margin-top: 30px;
}

.attendance-notes {
  width: 99%;
  height: 10rem;
}

/* Styles to Student Assignment Review Modal */
.link {
  color: cornflowerblue;
  text-decoration: underline;
  cursor: pointer;
  font-family: Open Sans;
  font-display: swap;
}

p,
#notes {
  font-family: Open Sans;
  font-display: swap;
}

.assignment-review--name {
  font-size: 20px;
}

.assignment--btn {
  float: right;
  margin-left: 2em;
}

/* remove arrow from summary tag */
.link::marker {
  content: "";
}

/* Styles to Past Sessions */
.past--sessions-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 40px;
}

.past--sessions-h1 {
  margin: 0px 20px;
  padding: 0px 20px;
}

.past--sessions-searchbar {
  display: flex;
  align-items: center;
  width: 60%;
}

.po-searchbar {
  padding: 0;
}

.past--sessions-searchbar-input {
  width: 100%;
  padding: 6px;
  font-size: 14px;
}

@media (max-width: 900px) and (min-width: 768px) {
  .past--sessions-searchbar {
    width: 60%;
  }
  .past--sessions-header {
    justify-content: space-around;
  }
}

@media (max-width: 768px) {
  .past--sessions-searchbar {
    padding: 20px 20px;
    width: 100%;
  }
  .po-searchbar {
    padding: 0;
    margin: 20px 20px 0px 20px;
  }
}
/* past students search styles */
.search-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.search-container input {
  margin-right: 10px;
}

.search-wrapper {
  width: calc(100% - 60px);
}

/* .search-input {
  padding: 0.5em;
} */
.dropdown-container {
  position: relative;
}

.dropdown {
  position: absolute;
  top: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 1;
  list-style: none;
  width: 100%;
  background-color: #fff;
}
.dropdown-link {
  color: rgb(0, 0, 0);
  line-height: 26pt;
  background-color: #fff;
  font-family: "Open Sans";
  font-display: swap;
}

.past {
  background-color: #1c4d7c;
  color: white;
  border: #ccc;
  padding: 0.5em;
  font-weight: bold;
  cursor: pointer;

  font-family: "Open Sans";
  font-display: swap;
  border: none;
  padding: 0.5em;
  width: 60px;

  display: inline-block;
}
.dropdown-link {
  text-decoration: none;
}
.search-button {
  color: white;
  cursor: pointer;
  background-color: #1c4d7c;
  font-family: "Open Sans";
  font-display: swap;
  border-radius: 0.5em;
  border: none;
  font-size: 14px;
  text-align: center;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 1.5em;
  padding-right: 1.5em;
}

/* Styles to Instructor Uploads Page */
.uploaded-documents-h2 {
  font-size: 1.1rem;
}

.instructor-uploads-container {
  width: 70%;
  margin: auto;
}

.ins-uploads-form {
  min-height: 251px;
}

.instructor-uploads-area {
  max-width: 100% !important;
  min-width: 0 !important;
}

.instructor-uploads-select {
  height: 50px;
  width: 200px;
}

.mt {
  margin-top: 30px;
}

.ins-uploads-page {
  margin-bottom: 50px;
}

.ins-uploads-page hr {
  display: none;
}

@media (max-width: 930px) {
  .ins-uploads-page hr {
    display: block;
    width: 80%;
  }
}

.ins-uploads-divider {
  display: block;
  height: 100%;
  width: 100%;
  background-color: #123b62;
}

@media (max-width: 930px) {
  .ins-uploads-divider {
    background-color: transparent !important;
  }
}

.grid-3sp {
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
}

@media (max-width: 930px) {
  .grid-3sp {
    display: block;
  }
}

/* Styles to Current Clients Page */
.current-clients-container {
  box-shadow: -1px 3px 6px 0 rgb(52 52 52 / 35%);
  width: 300px;
  margin: auto;
  text-align: center;
  border: 1px solid #123b62;
}

.current-clients-div {
  height: 2.5em;
  border: 1px solid #123b62;
  text-align: center;
  font-family: "Open Sans";
  font-display: swap;
  color: #123b62;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffffa3;
}

.current-clients-name {
  color: #123b62;
  display: inline;
  font-weight: 600;
}

.current-clients-name:hover {
  text-decoration: underline;
  cursor: pointer;
}

/* Styles to PO Past Clients Page */
.pa-past-clients-container {
  width: 100%;
  margin-top: 20px;
}

/* instructor dash board and past students search dropdown */
.dropdown-container .dropdown {
  display: block;
  position: absolute;
  top: 100%;

  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  width: 100%;
  z-index: 999;
}

.dropdown-item {
  cursor: pointer;
  padding: 4px 8px;
}
.instructor--dashboard-searchbar {
  display: flex;
  align-items: center;
}
#users {
  padding: 5px 2px;
  font-family: "Open Sans";
  font-display: swap;
}

.dropdown-container {
  position: relative;
  flex: 1;
  width: 96%;
  margin: 0 7px;
  font-family: "Open Sans";
  font-display: swap;
}

.dropdown-container2 {
  position: relative;
  flex: 1;
  font-family: "Open Sans";
  font-display: swap;
}

.instructor--dashboard-searchbar-input {
  width: 550px;
  padding: 5px 2px;
  font-family: "Open Sans";
  font-display: swap;
}

@media (min-width: 1140px) {
  .instructor--dashboard-searchbar-input {
    width: 509px;
  }
}

@media (max-width: 768px) {
  .instructor--dashboard-searchbar-input {
    width: 100%;
  }
  .instructor--dashboard-searchbar {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.assessment_button {
  box-shadow: -1px 3px 6px 0 rgb(52 52 52 / 35%);
  margin-left: 10px;
  padding: 8px 17px;
  font-size: 14px;
  background-color: #1c4d7c;
  color: white;
  font-family: "Open Sans";
  font-display: swap;
  border: none;
  padding: 0.5em;
  font-weight: bold;
  cursor: pointer;
  display: inline-block;
}

.dropdown-items {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px;
  width: 100%;
}

/* Styles to Info Pages */
.usertype-info {
  padding-left: 20px;
  margin: auto;
  margin-top: 20px;
}

.info-row {
  display: flex;
}

.info-label {
  width: 150px;
  font-weight: bold;
}

.info-address {
  max-width: 300px;
}

/* Styles to Super User Dashboard */
.actions-btn {
  background-color: transparent;
  border: none;
  color: #123b62;
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
}

.create-notice-form {
  text-align: left;
}

.create-notice-textarea-container {
  width: 90%;
  margin: auto;
}

.create-notice-textarea {
  width: 100%;
  min-height: 70px;
}

.create-notice-selections {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 2px;
  margin-bottom: 15px;
}

.create-notice-select {
  padding: 5px 2px;
  font-family: "Open Sans";
  font-display: swap;
}

.create-notice-btn {
  color: white;
  cursor: pointer;
  background-color: #1c4d7c;
  font-family: "Open Sans";
  font-display: swap;
  border-radius: 0.5em;
  border: none;
  font-size: 14px;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  margin-top: 20px;
}

/* Styles to Super User Lessons Page */
.link--btn {
  color: #123b62;
  text-decoration: underline;
  cursor: pointer;
  font-family: Open Sans;
  font-display: swap;
  background-color: transparent;
  border: none;
  font-size: 14px;
}

/* Styles to Probation Officer Info/Activation Page */
.parole-officer--infoBtns {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 150px;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
}

.parole-officer--infoBtns button {
  display: block;
  margin: 10px 0;
}

.parole-officer--assignBtn-contianer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Styles to Student and PO Assignment Page */
@media (max-width: 767px) {
  .paroleOfficerInfo {
    display: none;
  }
}

.student-search--form {
  margin-top: 40px;
}

.student-search--form input,
.student-search--form button {
  height: 40px;
}

.student-search--form input {
  width: 50.5%;
  margin-right: 0.5%;
}
.student-search--form button {
  width: 48%;
  background-color: #123b62;
  border: none;
  color: white;
}

.student-options--form {
  height: 500px;
  overflow: scroll;
  overflow-x: hidden;
  border: 1px solid rgba(128, 128, 128, 0.574);
}

.student-options--form-table {
  margin: 0;
}

.student-options--form-btn {
  display: block;
  margin: 50px auto;
}

@media (max-width: 767px) {
  .student-options--form,
  .student-search--form {
    margin: 20px;
  }

  .student-options--form {
    margin-top: 0px;
  }
  .student-search--form {
    margin-bottom: 0px;
  }
}

/* Styles to Super User Upcoming Sessions page */
.SU-upcoming-header {
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 30px;
}

.SU-upcoming-header h1 {
  font-size: 20px;
  font-weight: bold;
}

.SU-upcoming-header button {
  margin-top: 40px;
  color: white;
  cursor: pointer;
  background-color: #1c4d7c;
  font-family: "Open Sans";
  font-display: swap;
  border-radius: 0.5em;
  border: none;
  font-size: 14px;
  padding: 0.5em 1em;
  box-shadow: -1px 3px 6px 0 rgb(52 52 52 / 35%);
}

.edit-session-btn {
  background-color: transparent;
  border: none;
  color: #123b62;
  cursor: pointer;
}

.disabled-btn {
  opacity: 0.25;
  cursor: auto;
}

.SU-upcoming-table tr th:nth-child(4) {
  width: 10%;
}

/* Styles to Super User Add A Session Page */
#course,
#time,
#instructor,
#date {
  height: 2em;
  width: 65%;
}

@media (max-width: 768px) {
  #course,
  #time,
  #instructor,
  #date {
    height: 2em;
    width: 90%;
  }
}

.react-calendar__tile--active {
  background-color: #123b62 !important;
}

.save-session {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

/* Styles to Super User Student Assignment Page */
.login--btn {
  cursor: pointer;
  font-family: Open Sans;
  font-display: swap;
  background-color: #d22b2b;
  border: none;
  font-size: 14px;
  border-radius: 0.5em;
  border: none;
  font-size: 14px;
  text-align: center;
  margin: auto;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 0.5em;
  padding-right: 0.5em;
}
.login--btn-2 {
  cursor: pointer;
  font-family: Open Sans;
  font-display: swap;
  background-color: #228b22;
  border: none;
  font-size: 14px;
  border-radius: 0.5em;
  border: none;
  font-size: 14px;
  text-align: center;
  margin: auto;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

/* Styles for the container */
.uploads-container {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(300px, 1fr)
  ); /* Adjust the minimum width to 300px */
  gap: 16px;
  justify-content: center;
  overflow-x: auto;
  padding: 16px;
  justify-items: center;
  max-height: 500px;
  overflow: scroll;
  margin-bottom: 50px;
  overflow-x: hidden;
}

/* Styles for superuser and instructor uploads cards */
.uploads--card {
  min-width: 250px;
  max-width: 300px;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid black;
  margin-top: 2em;
  margin-bottom: 2em;
  border-radius: 0.5em;
  box-shadow: 2px 2px 2px gray;
  background-color: white;
  font-family: "Open Sans";
  font-display: swap;
  text-align: center;
}
.uploads-heading {
  display: block;
  font-size: 1.17em;
  margin-block-start: 0em;
  padding-top: 1em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  color: black;
}
.uploads--card p {
  margin: 0;
}
.uploads--card-sm_txt {
  font-size: 0.7rem;
}

.uploads--card-sm_txt.student {
  margin-bottom: 1.5rem;
}
.uploads--card h3 {
  margin: 8px 0;
}

.uploads--card--hr {
  border: 1px solid #ccc;
  margin: 8px 0;
}

.uploads--card--btn {
  background-color: #1c4d7c;
  border: none;
  border-radius: 0.5em;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  margin: auto;
  padding: 1em;
  margin-bottom: 1em;
  font-weight: 600;
}
/* Styles for screen sizes greater than 760px (Mobile) and between 768px to 1140px (Desktop) */
/* Search bar Instructor/Super User dashboard */
@media (min-width: 768px) and (max-width: 1140px) {
  .instructor--dashboard-header {
    flex-direction: column;
    align-items: center;
  }

  .instructor--dashboard-h1 {
    margin-bottom: 10px;
  }

  .instructor--dashboard-searchbar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 400px;
    margin-bottom: 10px;
  }

  .instructor--dashboard-searchbar-input {
    flex: 2;
    width: 100%;
    margin-right: 5px;
  }

  .assessment_button {
    max-width: auto;
    margin-left: 5px;
  }
}

/* Styles for screen sizes between 768px and 903px */
/* Officer past clients */
@media (min-width: 768px) and (max-width: 903px) {
  .past--sessions-searchbar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .po-searchbar {
    flex-wrap: nowrap;
  }

  .assessment_button {
    margin-left: 5px;
  }
}

/* Styles to Instructor Edit Session Page */
.edit-session-container {
  width: 50%;
  margin: auto;
}

@media (max-width: 768px) {
  .edit-session-container {
    width: 80%;
  }
}

.edit-session-details {
  text-align: center;
}

.edit-session-select {
  width: 100% !important;
}

.rmdp-day.rmdp-selected span:not(.highlight),
.rmdp-panel-body li,
.rmdp-panel-body li.bg-blue {
  background-color: #123b62 !important;
}

.rmdp-week-day {
  color: #123b62 !important;
}

.rmdp-panel-body li .b-deselect {
  background-color: #c1902d !important;
}

.rmdp-input {
  border: 1px solid rgba(0, 0, 0, 0.56) !important;
  border-radius: 2px !important;
}

.rmdp-day.rmdp-today span {
  background-color: #1c4d7c !important;
  color: #fff;
}
/* session schedule styles */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.calendar-container {
  display: flex;
  margin-right: 2%;
}

.container > div > div {
  z-index: 0 !important;
}

/* Styles to Partner Organizations */
.partner-org-badges {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 40px;
}

.partner-org-badges img {
  cursor: pointer;
}

.dv-img {
  height: 100px;
  margin: 0 40px;
  margin-bottom: 20px;
}

@media (max-width: 300px) {
  .dv-img {
    height: auto;
    width: 100%;
  }
}
/* Fix: Allow instructors to dismiss a notice btn */
.notice-dismiss_btn {
  background-color: transparent;
  border-radius: 5px;
  color: #0f3459;
  border: 1px solid #0f3459;
}

.notice-dismiss_btn:hover {
  cursor: pointer;
}

/* Fix: Add absent fee for anyone missing a group session */
.absentee-fee_btn {
  background-color: #0f3459;
  border: 1px solid #0f3459;
  border-radius: 5px;
  color: #fff;
  margin: 10px;
}

.absentee-fee_btn:hover {
  background-color: transparent;
  color: #0f3459;
  cursor: pointer;
}

.class-payment-header {
  color: #123b62;
  text-align: center;
  width: 100%;
  font-size: 1rem;
}

.pay-btns-container {
  width: 70%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 70px;
}

.pay-btns-container > div {
  width: 45%;
  text-align: center;
}
/* privacy policy styles */
.privacy-policy {
  margin: 0rem 6rem 2rem 6rem;
}
.privacy-policy-header,
h4 {
  color: #000;
  font-family: "Open Sans";
}

/* student dashboard intake form button styles */
.intake-form-btn {
  background-color: #1c4d7c;
  color: white;
  font-family: "Open Sans";
  font-display: swap;
  border: none;
  padding: 1em 2em 1em 2em;
  cursor: pointer;
  border-radius: 0.25em;
  box-shadow: -1px 3px 6px 0 rgb(52, 52, 52);
  margin: 25px 25px;
}
/* Add View Details to Student Info Page btn */
.view-details-btn {
  background-color: #c1902d;
  color: #102c47;
  font-family: "Open Sans";
  font-weight: bold;
  font-display: swap;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 0.25em;
  margin: 5px auto;
}
/* Catalog placeholder text styles */
.catalog-placeholder-text {
  width: 80%;
  margin: auto;
  margin-top: 50px;
}

/* Styles to Group Rules page */
.group-rules-ol li {
  padding-top: 20px;
}

.group-rules-link {
  display: block;
  font-size: 0.85em !important;
  text-decoration: none;
  margin-top: 10px;
}

.group-rules-link:hover {
  text-decoration: underline;
}

/* Fix: Add certificates link */
.footer-certificates {
  font-family: "Open Sans";
  color: #c1902d;
}
.footer-nav-links a {
  display: block;
  margin-bottom: 10px;
}

/* Styles to Certificates Page*/
.cert-container {
  width: 70%;
  margin: auto;
  margin-bottom: 30px;
}

.cert-title,
.cert-link-container {
  display: flex;
  align-items: center;
  justify-content: left;
}
.cert-title p {
  margin-left: 7px;
  margin-right: 7px;
}
.cert-link {
  color: blue;
  text-decoration: underline;
  margin-right: 7px;
}
.cert-link:hover {
  cursor: pointer;
}
.cert-title p,
.cert-link-container p {
  margin-top: 5px;
  margin-bottom: 5px;
}

/* Email Validation Alert */
.email-alert {
  color: red;
  font-size: 0.8rem;
  margin: 0;
  padding: 0;
  text-align: center;
}
