/* Fix spacing for header */
.p-0 {
  padding: 0;
}
.p-10_40 {
  padding: 20px 40px;
}
@media only screen and (max-width: 425px) {
  /*Small smartphones [325px -> 425px]*/
  .p-10_40 {
    padding: 10px 20px;
  }
}
.progress-modal-header {
  background-color: #123b62;
  position: relative;
}
/* Styles to Modal */
.progress-modal-header h1 {
  color: whitesmoke !important;
  margin-block-start: 0;
  padding-bottom: 20px;
  padding-top: 20px;
}
@media only screen and (max-width: 630px) {
  .progress-modal-header h1 {
    width: 75%;
    padding-left: 10px;
  }
}
.sp-close-btn {
  position: absolute;
  right: 20px;
  top: 20px;
  background-color: #123b62;
  color: whitesmoke;
  border: none;
  font-size: 1.3rem;
  cursor: pointer;
}
.progress-info-container p {
  margin: 0;
}
.progress-heading {
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
  color: #123b62;
  font-family: "Open Sans";
  font-display: swap;
}
@media only screen and (max-width: 425px) {
  /*Small smartphones [325px -> 425px]*/
  .progress-heading {
    margin-top: 50px;
  }
}
/* Styles to Lessons Table */
.lessons-progress-container {
  height: 200px;
  border: 1px solid #123b62;
  overflow: auto;
}
.lessons-progress-container div {
  padding: 17px 10px;
  cursor: pointer;
}
.lessons-progress-container div:nth-child(even) {
  background-color: #eeeeee8f;
}

.meeting-table-container {
  overflow: auto;
}
